import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { HorizontalBar } from "react-chartjs-2"
import SourceLink from "./source-link"

export default ({region, schoolDistricts}) => {

  const data = useStaticQuery(graphql`
    {
      enrollment: allSchoolEnrollmentCsv {
        edges {
          node {
            District
            Count
          }
        }
      }
      sources: allSourcesCsv {
        edges {
          node {
            Dataset
            Source
            Year
            Link
          }
        }
      }
    }
  `)

  // From all towns, select only the relevant ones, and sort by value
  const regionData = data.enrollment.edges
    .filter(o => (schoolDistricts.includes(o.node.District) && parseInt(o.node.Count)))
    .map(function(o) { let newO = o; newO.node.Count = parseInt(newO.node.Count); return newO }) 
    .sort((a,b) => (a.node.Count > b.node.Count) ? -1 : ((b.node.Count > a.node.Count) ? 1 : 0))  // Sort from largest to smallest

  const districtsSuppressed = regionData.filter(o => isNaN(o.node.Count)).length
  const totalEnrollment = regionData.filter(o => !isNaN(o.node.Count)).reduce((a,b) => a + b.node.Count, 0)

  const enrollmentLabels = regionData.map(o => o.node.District.indexOf('Regional') === 0 ? o.node.District : o.node.District.split('School District')[0])
  const enrollmentNumbers = regionData.map(o => o.node.Count)

  const source = data.sources.edges.filter(o => o.node.Dataset === 'schoolEnrollment')[0]


  return ( 
    <>
      <div className="">
        There are <b>{schoolDistricts.length}</b> school districts in {region} with
        a total enrollment of {totalEnrollment.toLocaleString()} students in the {source.node.Year} academic year
        {districtsSuppressed > 0 ?
          <span>
          &nbsp;({districtsSuppressed} districts suppressed their enrollment data due
          to privacy concerns, so the actual enrollment number is slightly higher).
          </span> : '.'}

          <p>
            By enrollment,&nbsp;
            {enrollmentLabels[0]} ({enrollmentNumbers[0].toLocaleString()} students),&nbsp;
            {enrollmentLabels[1]} ({enrollmentNumbers[1].toLocaleString()} students), 
            and {enrollmentLabels[2]} ({enrollmentNumbers[2].toLocaleString()} students)
            are the largest school districts in the county.
          </p>
      </div>

      <h4 className="f4">Enrollment by School District in {region}</h4>


      <div className="">
        <HorizontalBar height={200} data={
          {
            labels: enrollmentLabels,
            datasets: [{
              data: enrollmentNumbers,
              backgroundColor: 'rgba(0,0,80,1)'
            }]
          }
        } options={
          {
            legend: {
              display: false,
            },
            scales: {
              xAxes: [
                { 
                  display: false,
                  gridLines: {
                    display: false,
                    drawBorder: false,
                  },
                  ticks: {
                    beginAtZero: true,
                  }
                }
              ],
              yAxes: [
                {
                  gridLines: {
                    display: false,
                    drawBorder: false,
                  }
                }
              ]
            },
            tooltips: {
              displayColors: false,
              callbacks: {
                label: function(tooltipItem, data) {
                  return parseInt(tooltipItem.value).toLocaleString() + ' students'
                },
              }
            },
            plugins: {
              datalabels: {
                display: true,
                align: 'left',
                anchor: 'end',
                color: 'white',
                formatter: function(value) {
                  return value > 1500 ? value.toLocaleString() : ''
                }
              }
            }
          }
        } />

        <SourceLink href={source.node.Link} source={source.node.Source} year={source.node.Year} />

      </div>

    </>
  )
}