import React from "react"

export default ({ href, source, year }) => {
  return (
    <a 
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className="f7 link bt b--dark-gray dark-gray hover-blue">

        Source: {source}, {year}
        
    </a>
  )
}