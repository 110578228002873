import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import SourceLink from "./source-link"
import { FaMale, FaFemale } from "react-icons/fa"

export default ({region, towns}) => {

  const data = useStaticQuery(graphql`
    {
      youth: allDisengagedYouthCsv {
        nodes {
          Town
          FemaleYouthTotal
          FemaleYouthDisengaged
          MaleYouthTotal
          MaleYouthDisengaged
        }
      }
      sources: allSourcesCsv {
        nodes {
          Dataset
          Source
          Year
          Link
        }
      }
    }
  `)

  const regionData = data.youth.nodes.filter(o => (towns.includes(o.Town)))

  const regionMaleTotal = regionData.reduce((a,b) => a + parseInt(b.MaleYouthTotal), 0)
  const regionMaleDisengaged = regionData.reduce((a,b) => a + parseInt(b.MaleYouthDisengaged), 0)
  const regionMaleRate = (regionMaleDisengaged / regionMaleTotal * 100).toFixed(1)

  const regionFemaleTotal = regionData.reduce((a,b) => a + parseInt(b.FemaleYouthTotal), 0)
  const regionFemaleDisengaged = regionData.reduce((a,b) => a + parseInt(b.FemaleYouthDisengaged), 0)
  const regionFemaleRate = (regionFemaleDisengaged / regionFemaleTotal * 100).toFixed(1)

  const ctData = data.youth.nodes.filter(o => o.Town === 'Connecticut')[0]
  const ctFemaleRate = (parseInt(ctData.FemaleYouthDisengaged) / parseInt(ctData.FemaleYouthTotal) * 100).toFixed(1)
  const ctMaleRate = (parseInt(ctData.MaleYouthDisengaged) / parseInt(ctData.MaleYouthTotal) * 100).toFixed(1)

  const source = data.sources.nodes.filter(o => o.Dataset === 'disengagedYouth')[0]

  return ( 
    <>
      <h3 className="f3 mt5">Disengaged Youth</h3>

      <p>
        Youth are considered disengaged when they are between the ages of 16-19,
        not enrolled in school, and either unemployed or not in the labor force.
        In Connecticut, {ctFemaleRate}% of female youth and {ctMaleRate}% of
        male youth are disengaged. In {region}, 
      </p>

      <div className="cf tc mv4">
        <div className="w-50 fl">
          <FaFemale className="f1 red" />
          <p className="mv1 f3">{regionFemaleRate}%</p>
          <p className="mv0 f5">of female youth are disengaged</p>
        </div>
        <div className="w-50 fl">
          <FaMale className="f1 red" />
          <p className="mv1 f3">{regionMaleRate}%</p>
          <p className="mv0 f5">of male youth are disengaged</p>
        </div>
      </div>

      <SourceLink href={source.Link} source={source.Source} year={source.Year} />

    </>
  )
}