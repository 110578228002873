import React from "react"
import { graphql, useStaticQuery } from "gatsby"

export default ({region, schoolDistricts}) => {

  const data = useStaticQuery(graphql`
    {
      bullying: allSchoolBullyingCsv {
        edges {
          node {
            DISTRICT
            Counts_of_Bullying_Incidents
          }
        }
      }
      sources: allSourcesCsv {
        edges {
          node {
            Dataset
            Source
            Year
            Link
          }
        }
      }
    }
  `)

  const regionData = data.bullying.edges
    .filter(o => (schoolDistricts.includes(o.node.DISTRICT)))

  var bullyingCount = 0
  var bullyingSuppressed = 0

  for (let i in regionData) {
    var n = regionData[i].node.Counts_of_Bullying_Incidents
    if (isNaN(n)) {
      bullyingSuppressed += 1
    } else {
      bullyingCount += parseInt(n)
    }
  }

  const source = data.sources.edges.filter(o => o.node.Dataset === 'schoolBullying')[0]

  return ( 
    <>
      <h3 className="f3 mt5">Bullying Incidents</h3>

      In {source.node.Year}, there were {bullyingCount} bullying
      incidents in {region}. {bullyingSuppressed} of {regionData.length} school
      districts that reported bullying incidents suppressed their data.<br></br>
      
      <i class='f6'>Note: In the 2020-21 school year, due to the COVID-19 pandemic, students attended school in-person to varying degrees; some learned fully/mostly remotely for the entire school year.</i>
    </>
    
  )
}