import React from "react"
import EconomyHHIncome from "./economy-hh-income"
import EconomyHHIncomeRegions from "./economy-hh-income-regions"
import EconomyLabor from "./economy-labor"
import EconomyUnemploymentRegions from "./economy-unemployment-regions"

export default ({ region, regions, towns }) => {

  return (
    <section className="bg-white">
      <div className="mw8 center ph3 pv5">
        <h1 className="f1">Economy</h1>

        <EconomyHHIncome region={region} towns={towns} />
        <EconomyHHIncomeRegions region={region} regions={regions} />

        <EconomyLabor region={region} towns={towns} />
        <EconomyUnemploymentRegions region={region} regions={regions} />
        
      </div>
    </section>
  )
}