import React from "react"

const Layout = ({children}) => {
  return (
    <article className="bg-white sans-serif f4 lh-copy">
      {children}
    </article>
  )
}

export default Layout;