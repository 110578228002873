import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { HorizontalBar } from "react-chartjs-2"
import SourceLink from "./source-link"
import 'chartjs-plugin-colorschemes'

export default ({region, schoolDistricts}) => {

  const data = useStaticQuery(graphql`
    {
      enrollmentByRace: allSchoolEnrollmentByRaceCsv {
        edges {
          node {
            District
            American_Indian_or_Alaska_Native
            Asian
            Black_or_African_American
            Hispanic_Latino_of_any_race
            Native_Hawaiian_or_Other_Pacific_Islander
            Two_or_More_Races
            White
            Total
          }
        }
      }
      sources: allSourcesCsv {
        edges {
          node {
            Dataset
            Source
            Year
            Link
          }
        }
      }
    }
  `)

  // From all school districts, select only the relevant ones
  const regionData = data.enrollmentByRace.edges
    .filter(o => (schoolDistricts.includes(o.node.District)))

  // Define a function to calculate number of students of a race
  let getCount = function(race) {
    return regionData.filter(o => !isNaN(o.node[race])).reduce((a, b) => a + parseInt(b.node[race]) , 0)
  }

  // Get total enrollment
  let totalEnrollment = getCount('Total')

  // Get races and % to use in the paragraph above the chart
  let asian = getCount('Asian')
  let asianPercent = Math.round(asian / totalEnrollment * 100)
  let black = getCount('Black_or_African_American')
  let blackPercent = Math.round(black / totalEnrollment * 100)
  let hispanic = getCount('Hispanic_Latino_of_any_race')
  let hispanicPercent = Math.round(hispanic / totalEnrollment * 100)
  let white = getCount('White')
  let whitePercent = Math.round(white / totalEnrollment * 100)


  // Create a final data object for the chart
  const enrollmentByRaceObjects = [
    {
      label: 'American Indian / Alaska Native',
      data: [getCount('American_Indian_or_Alaska_Native')]
    },
    {
      label: 'Asian',
      data: [asian]
    },
    {
      label: 'Black',
      data: [black]
    },
    {
      label: 'Hispanic / Latino',
      data: [hispanic]
    },
    {
      label: 'Native Hawaiian / Pacific Islander',
      data: [getCount('Native_Hawaiian_or_Other_Pacific_Islander')]
    },
    {
      label: 'Two or more races',
      data: [getCount('Two_or_More_Races')]
    },
    {
      label: 'White',
      data: [white]
    },
  ]

  const source = data.sources.edges.filter(o => o.node.Dataset === 'schoolEnrollment')[0]

  return ( 
    <>
      <h4 className="f4">Enrollment by Race</h4>

      <p>
        Because of data supression by CT Department of Education, we cannot determine exact counts
        of students of certain minority groups in many school districts. An approximate racial
        profile of students in {region} is {whitePercent}% white non-Hispanic, {blackPercent}%
        black non-Hispanic, {asianPercent}% Asian non-Hispanic, and {hispanicPercent}% Hispanic or Latino.
      </p>

      <div>

        <HorizontalBar height={80} data={
          {
            labels: ['Students by Race/Ethnicity'],
            datasets: enrollmentByRaceObjects
          }
        } options={
          {
            legend: {
              display: true,
              position: 'top'
            },
            scales: {
              xAxes: [
                { 
                  stacked: true,
                  ticks: {
                    beginAtZero: true,
                    display: false,
                  },
                  gridLines: {
                    display: false,
                    drawBorder: false,
                  }
                }
              ],
              yAxes: [
                {
                  display: false,
                  stacked: true,
                  ticks: {
                    display: false,

                  },
                  gridLines: {
                    display: false,
                    drawBorder: false,
                  }
                }
              ]
            },
            tooltips: {
              callbacks: {
                label: function(tooltipItem, data) {
                  return data.datasets[tooltipItem.datasetIndex].label + ': ' + parseInt(tooltipItem.value).toLocaleString() + ' ('
                    + Math.round(tooltipItem.value / totalEnrollment * 100) + '%)';
                }
              }
            },
            plugins: {
              datalabels: {
                display: true,
                align: 'center',
                anchor: 'center',
                formatter: function(value, context) {
                  return value > 5000 ? Math.round(value / totalEnrollment * 100) + '%' : null;
                },
                color: 'black'
              },
              colorschemes: {
                scheme: 'brewer.PuBu7'
              }
            }
          }
        } />

        <SourceLink href={source.node.Link} source={source.node.Source} year={source.node.Year} />

      </div>

    </>
  )
}