import React from "react"
import PopulationAge from "./population-age"
import PopulationRace from "./population-race"

export default ({ region, towns }) => {
  return (
    <section className="bg-moon-gray">
      <div className="mw8 center pa3">
        <h1 className="f1">Demographics</h1>

        <PopulationAge region={region} towns={towns} />

        {
          region !== 'Tolland County'
          ? <PopulationRace region={region} towns={towns} />
          : null
        }
         
      </div>
    </section>
  )
}