import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Pie } from "react-chartjs-2"
import SourceLink from "./source-link"
import 'chartjs-plugin-colorschemes'

export default ({region, schoolDistricts}) => {

  const data = useStaticQuery(graphql`
    {
      enrollmentByLunch: allSchoolEnrollmentByLunchCsv {
        edges {
          node {
            District
            Free
            Non_Subsidized
            Reduced
            Total
          }
        }
      }
      enrollmentByEnglishLearner: allSchoolEnrollmentByEnglishLearnerCsv {
        edges {
          node {
            District
            Yes
            No
            Total
          }
        }
      }
      enrollmentBySpecialEducation: allSchoolEnrollmentBySpecialEducationCsv {
        edges {
          node {
            District
            Yes
            No
            Total
          }
        }
      }
      sources: allSourcesCsv {
        edges {
          node {
            Dataset
            Source
            Year
            Link
          }
        }
      }
    }
  `)

  // Function to return sum of property values in an array of obj
  let getSum = function(arr, prop) {
    return arr.filter(o => !isNaN(o.node[prop])).reduce((a, b) => a + parseInt(b.node[prop]) , 0)
  }

  // Calculate English learners
  const engObj = data.enrollmentByEnglishLearner.edges
    .filter(o => (schoolDistricts.includes(o.node.District)))
  const eng = [getSum(engObj, 'Yes'), getSum(engObj, 'No')]

  const totalEnrollment = getSum(engObj, 'Total')

  // Calculate free/subsidized lunch
  const lunchObj = data.enrollmentByLunch.edges
    .filter(o => (schoolDistricts.includes(o.node.District)))
  const lunch = [getSum(lunchObj, 'Free'), getSum(lunchObj, 'Reduced'), getSum(lunchObj, 'Non_Subsidized')]

  // Calculate Special Ed
  const specialObj = data.enrollmentBySpecialEducation.edges
    .filter(o => (schoolDistricts.includes(o.node.District)))
  const special = [getSum(specialObj, 'Yes'), getSum(specialObj, 'No')]

  const source = data.sources.edges.filter(o => o.node.Dataset === 'schoolEnrollment')[0]

  const pieOptions = {
    legend: {
      position: 'bottom',
      onClick: function() {return false}
    },
    elements: {
      arc: {
        borderWidth: 1
      }
    },
    tooltips: {
      displayColors: false,
      callbacks: {
        label: function(item, data) {
          return data.labels[item.index] + ': '
            + data.datasets[item.datasetIndex].data[item.index].toLocaleString()
        }
      }
    },
    plugins: {
      datalabels: {
        display: true,
        align: 'center',
        color: 'white',
        formatter: function(value) {
          const percent = Math.round(value / totalEnrollment * 100)
          return percent > 5 ? percent + '%' : null
        }
      }
    }
  }

  return ( 
    <>
      <h3 className="f3">Educational Needs</h3>

      <p>
        {region} has {eng[0].toLocaleString()} students who are English Language learners.
        {' '}{(lunch[0] + lunch[1]).toLocaleString()} students are eligible for either free
        or subsidized lunch ({Math.round((lunch[0] + lunch[1]) / totalEnrollment * 100 )}%
        of all students). There are {special[0].toLocaleString()} students receiving special education.
      </p>

      <div className="cf">

        <div className="w-100 measure-narrow w-third-l fl">
          <h4 className="f4">English Learners</h4>

          <Pie height={200} data={
            {
              datasets: [{ data: eng, backgroundColor: ['navy', 'silver'] }],
              labels: ['Yes', 'No']
            }
          } options={ pieOptions } />
        </div>

        <div className="w-100 measure-narrow w-third-l fl">
          <h4 className="f4">Free/Subsidized Lunch</h4>

          <Pie height={200} data={
            {
              datasets: [{ data: lunch, backgroundColor: ['navy', 'blue', 'silver'] }],
              labels: ['Free', 'Reduced', 'Non-Subsidized']
            }
          } options={ pieOptions } />
        </div>

        <div className="w-100 measure-narrow w-third-l fl">
          <h4 className="f4">Special Education</h4>

          <Pie height={200} data={
            {
              datasets: [ { data: special, backgroundColor: ['navy', 'silver'] } ],
              labels: ['Yes', 'No']
            }
            } options={ pieOptions } />
        </div>

      </div>

      <SourceLink href={source.node.Link} source={source.node.Source} year={source.node.Year} />

    </>
  )
}