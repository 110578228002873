import React from "react"

export default () => {
  return (
    <section className="bg-moon-gray">
      <div className="mw8 center ph3 pv5">
        <h1 className="f1">Housing</h1>
        
        <h3 className="f3">Federally Subsidized Housing in Connecticut</h3>

        <p>
          The map shows properties that participate in at least one subsidy program,
          according to the National Housing Preservation Database. Hover over the green
          dots to see address and number of units in each property. <u>This is not an
          exhaustive list of affordable housing units in Connecticut.</u>
        </p>

        <iframe
          title="Affordable Map Iframe"
          className="w-100"
          frameBorder="0"
          style={{height: 600}}
          src="https://ct-data-collaborative.github.io/affordable-housing-map/#8/41.5077/-72.7107"
        />

        <h3 className="f3 mt5">Equalized Net Grand List</h3>

        <p>
          The Net Grand List is an aggregate of the assessed value of taxable property in the town.
          The Office of Policy and Management (OPM) equalizes each town's grand list since valuations
          occur once every four years. Thus the value of a town that recently went through an
          assessment could vary from a town that is several years away from its last valuation.
        </p>

        <p>
          As a way to understand the different values across towns,
          CTData provides a per capita (per person) calculation.
        </p>

        <p>
          In Connecticut, towns along the coast (with some exceptions such as Bridgeport, New Haven, New London),
          and towns along the western border tend to have higher per capita equalized net grand list values.
        </p>
      </div>

      <div className="mw9 center pb5">

        <iframe
          title="Equalized Net Grand List"
          className="w-100"
          frameBorder="0"
          style={{height: 600}}
          src="https://ct-data-collaborative.github.io/grand-list-by-region-map/by-county.html"
        />
        
      </div>
    </section>
  )
}