import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Bar } from "react-chartjs-2"
import SourceLink from "./source-link"

export default ({region, regions}) => {

  const data = useStaticQuery(graphql`
    {
      laborForce: allLaborForceCsv {
        nodes {
          Geography
          Employed
          Unemployed
          LaborForce
          UnemploymentRate
        }
      }
      sources: allSourcesCsv {
        nodes {
          Dataset
          Source
          Year
          Link
        }
      }
    }
  `)

  const source = data.sources.nodes.filter(o => o.Dataset === 'laborForce')[0]

  const rateSorted = data.laborForce.nodes
    .filter(o => regions.concat(['Connecticut', region]).includes(o.Geography) )
    .map(function(o) { let newO = o; newO.UnemploymentRate = parseFloat(newO.UnemploymentRate); return newO })  // Apply parseInt() to Value property
    .sort((a,b) => (a.UnemploymentRate > b.UnemploymentRate) ? -1 : ((b.UnemploymentRate > a.UnemploymentRate) ? 1 : 0))  // Sort from largest to smallest

  const datasetValues = rateSorted.map(o => o.UnemploymentRate)
  const datasetLabels = rateSorted.map(o => o.Geography.split(' County')[0])
  const datasetColors = rateSorted.map(o => o.Geography === 'Connecticut' ? 'rgba(0,0,80,1)'
    : o.Geography === region ? 'orange' : 'teal')

  return ( 
    <>
      <h4>Unemployment Rate by County</h4>

      { region.indexOf(datasetLabels[0]) === 0
        ? <p>{region} has the highest unemployment rate of all counties in Connecticut.</p>
        : region.indexOf(datasetLabels[ datasetLabels.length - 1 ]) === 0
          ? <p>{region} has the lowest unemployment rate of all counties in Connecticut.</p>
          : ''
      }

      <Bar height={100} data={
        {
          labels: datasetLabels,
          datasets: [{
            data: datasetValues,
            backgroundColor: datasetColors,
          }]
        }
      } options={
        {
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              { 
                //display: false,
                gridLines: {
                  display: false,
                  drawBorder: false,
                },
                ticks: {
                  beginAtZero: true,
                }
              }
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  display: true,
                  drawBorder: false,
                },
                ticks: {
                  beginAtZero: true,
                }
              }
            ]
          },
          tooltips: {
            displayColors: false,
            callbacks: {
              label: function(tooltipItem, data) {
                return tooltipItem.value + '%'
              },
            }
          },
          plugins: {
            datalabels: {
              display: true,
              align: 'bottom',
              anchor: 'end',
              color: 'white',
              formatter: function(value) {
                return value + '%'
              }
            }
          }
        }
      } />

      <SourceLink href={source.Link} source={source.Source} year={source.Year} />

    </>
  )
}