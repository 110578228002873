import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { HorizontalBar } from "react-chartjs-2"
import SourceLink from "./source-link"

export default ({region, towns}) => {

  const data = useStaticQuery(graphql`
    {
      spf: allSingleParentFamiliesCsv {
        nodes {
          Geography
          SingleParentFamilies
          Percent
        }
      }
      sources: allSourcesCsv {
        nodes {
          Dataset
          Source
          Year
          Link
        }
      }
    }
  `)

  // From all towns, select only the relevant ones, and sort by value
  const sortedData = data.spf.nodes
    .filter(o => (towns.concat(['Connecticut', region]).includes(o.Geography)))
    .map(function(o) { let newO = o; newO.Percent = parseFloat(newO.Percent); return newO })  // Apply parseInt() to Value property
    .sort((a,b) => (a.Percent > b.Percent) ? -1 : ((b.Percent > a.Percent) ? 1 : 0))  // Sort from largest to smallest

  const dataCt = sortedData.filter(o => o.Geography === 'Connecticut')[0];
  const dataCounty = sortedData.filter(o => o.Geography === region)[0];

  const moreOrLess = parseFloat(dataCounty.Percent) > parseFloat(dataCt.Percent) ? 'more than'
    : parseFloat(dataCounty.Percent) === parseFloat(dataCt.Percent) ? 'the same as' : 'less than';

  const dataset = sortedData.map(o => o.Percent)
  const datasetLabels = sortedData.map(o => o.Geography)
  const datasetColors = sortedData.map(o => o.Geography === 'Connecticut' ? 'rgba(0,0,80,1)'
    : o.Geography === region ? 'orange' : 'teal')

  const source = data.sources.nodes.filter(o => o.Dataset === 'singleParentFamilies')[0]

  return ( 
    <>
      <h4 className="f4">Single-Parent Families by Town in {region}</h4>

      <div className="cf">
        <div className="w-100 w-50-l fl pr2">
          
          <p>
            In {region}, {dataCounty.Percent}% (or about {parseInt(dataCounty.SingleParentFamilies).toLocaleString()})
            of families are considered single-parent. This is { moreOrLess } the Connecticut average of {dataCt.Percent}%
            ({parseInt(dataCt.SingleParentFamilies).toLocaleString()} families).
          </p>

          <p>            
            <span className="bg-light-red">The
              largest share of single-parent families is in {datasetLabels[0]} ({dataset[0]}%).
            </span> At {dataset[dataset.length - 1]}%, {datasetLabels[datasetLabels.length - 1]} has the smallest share of
            single-parent families in {region}.
          </p>
        </div>


      <div className="w-100 w-50-l fl">
        <HorizontalBar height={300} data={
          {
            labels: datasetLabels,
            datasets: [{
              data: dataset,
              backgroundColor: datasetColors
            }]
          }
        } options={
          {
            legend: {
              display: false,
            },
            scales: {
              xAxes: [
                { 
                  display: false,
                  gridLines: {
                    display: false,
                    drawBorder: false,
                  },
                  ticks: {
                    beginAtZero: true,
                  }
                }
              ],
              yAxes: [
                {
                  gridLines: {
                    display: false,
                    drawBorder: false,
                  }
                }
              ]
            },
            tooltips: {
              displayColors: false,
              callbacks: {
                label: function(tooltipItem, data) {
                  return tooltipItem.value + '%'
                },
              }
            },
            plugins: {
              datalabels: {
                display: true,
                align: 'left',
                anchor: 'end',
                color: 'white',
                formatter: function(value) {
                  return value + '%'
                }
              }
            }
          }
        } />

        <SourceLink href={source.Link} source={source.Source} year={source.Year} />

      </div>
    
    </div>

    </>
  )
}