import React from "react"
import FamiliesSingleParent from "./families-single-parent"
import FamiliesSingleParentRegions from "./families-single-parent-regions"
import DisengagedYouth from "./disengaged-youth"

export default ({ region, regions, towns }) => {
  return (
    <section className="bg-washed-red">
      <div className="mw8 center ph3 pv5">
        <h1 className="f1">Families</h1>

        <FamiliesSingleParent towns={towns} region={region} />
        <FamiliesSingleParentRegions region={region} regions={regions} />

        <DisengagedYouth region={region} towns={towns} />
      </div>
    </section>
  )
}