import React from "react"
import Helmet from "react-helmet"

import Layout from "../components/layout"
import RegionMap from "../components/region-map"
import Population from "../components/population"
import Economy from "../components/economy"
import Families from "../components/families"
import School from "../components/school"
import Housing from "../components/housing"
import Footer from "../components/footer"

import { graphql, useStaticQuery } from "gatsby"

export default props => {
  const d = props.pageContext;

  // Load Connecticut town boundaries
  const geojson = useStaticQuery(graphql`
    {
      dataJson {
        type
        features {
          type
          properties {
            Town
          }
          geometry {
            type 
            coordinates
          }
        }
      }
    }
  `)

  return (
    <Layout>

      <Helmet>
        <title>{d.region} – County Data Stories by CTData Collaborative</title>
      </Helmet>

      <RegionMap region={d.region} towns={d.towns} geojson={geojson.dataJson} />

      <Population towns={d.towns} region={d.region} regions={d.regions} />

      <Economy towns={d.towns} region={d.region} regions={d.regions} />

      <Families towns={d.towns} region={d.region} regions={d.regions} />

      <School region={d.region} schoolDistricts={d.schoolDistricts} towns={d.towns} regions={d.regions} />

      <Housing />

      <Footer />

    </Layout>
  )
}

