import React from "react"
import { Map, GeoJSON } from "react-leaflet"
import { Link } from "gatsby"

export default class RegionMap extends React.Component {

  render() {

  const towns = this.props.towns;
  const region = this.props.region;
  const geojson = this.props.geojson;


  const style = function(feature) {
    let town = feature.properties.Town;

    return {
      'fillColor': towns.includes(town) ? 'rgba(0,0,73,1)' : 'rgba(0,0,0,0)',
      'fillOpacity': '0.4',
      'color': 'rgba(0,0,0,1)',
      'weight': 1
    }
  }

    return (
      <section className="mw8 center pa3 cf">
        <Link to="/" className="link blue dim">&larr; Back to all counties</Link>

        <h1 className="f1">{region}</h1>

        <p className="w-100 w-50-l fl">
          <b>{region}</b> is comprised of {towns.slice(0, -1).join(', ')},
          and {towns[towns.length - 1]}.
        </p>

        <div ref={this.mapWrapperRef}>
          {(typeof window !== 'undefined') ? (
            <Map ref={this.mapRef}
              style={{height: `290px`}}
              className={`w-100 w-50-l fl`}
              zoomControl={false}
              attributionControl={false}
              dragging={false}
              doubleClickZoom={false}
              keyboard={false}
              scrollWheelZoom={false}
              bounds={[[40.984022,-73.773555],[42.038866,-71.771888]]}
            >

            <GeoJSON data={geojson} style={style.bind(this)} />
              

            </Map>
          ) : null }
        </div>

      </section>
    )
  
}}