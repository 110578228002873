import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Bar } from "react-chartjs-2"
import SourceLink from "./source-link"

export default ({region, towns}) => {

  const data = useStaticQuery(graphql`
    {
      populationByAge: allPopulationByAgeCsv {
        nodes {
          Geography
          Sex
          Age
          Value
        }
      }
      sources: allSourcesCsv {
        edges {
          node {
            Dataset
            Source
            Year
            Link
          }
        }
      }
    }
  `)

  const ageGroups = [
    'Under 5 years', // 0
    '5 to 9 years',
    '10 to 14 years',
    '15 to 17 years',
    '18 and 19 years',
    '20 years', // 5
    '21 years',
    '22 to 24 years',
    '25 to 29 years',
    '30 to 34 years',
    '35 to 39 years', // 10
    '40 to 44 years',
    '45 to 49 years',
    '50 to 54 years',
    '55 to 59 years',
    '60 and 61 years',  // 15
    '62 to 64 years',
    '65 and 66 years',
    '67 to 69 years',
    '70 to 74 years',
    '75 to 79 years',   // 20
    '80 to 84 years',
    '85 years and over'
  ];

  // From all counties, select only the relevant one
  const regionData = data.populationByAge.nodes
    .filter(o => region === o.Geography)

  // Get total county population
  const totalPopulation = parseInt(regionData
    .filter(o => o.Age === `Total` && o.Sex === `Total`)[0].Value);

  let sumUp = (a, b) => a + parseInt(b.Value);
  let getPercentPop = (x) => Math.round(x / totalPopulation * 100);

  // Calculate Youth
  const youth = regionData
    .filter(o => ageGroups.slice(0, 5).includes(o.Age))
    .reduce(sumUp, 0);

  // Calculate Prime working age
  const primeAge = regionData
    .filter(o => ageGroups.slice(8,14).includes(o.Age))
    .reduce(sumUp, 0);

  // Calculate Elderly age
  const elderly = regionData
    .filter(o => ageGroups.slice(17).includes(o.Age))
    .reduce(sumUp, 0);

  // Return value for a single age group
  let getAgeGroupCount = function(age) {
    return regionData
      .filter(o => o.Age === age)
      .reduce(sumUp, 0);
  }

  // Create data object for Population by Age chart
  let dataset = {
    label: 'Population',
    backgroundColor: 'teal',
    data: ageGroups.map(getAgeGroupCount)
  }

  // Source
  const source = data.sources.edges.filter(o => o.node.Dataset === 'populationByAge')[0]

  return ( 
    <>
      <p>
        There are <b>{totalPopulation.toLocaleString()}</b> people living in {region}.
        Of these, <span className="bg-light-green"> {youth.toLocaleString()} ({getPercentPop(youth)}%) are youth</span> under 20
        years old. {primeAge.toLocaleString()}, or {getPercentPop(primeAge)}%, are of prime working age,
        or people between 25 and 54 years old. {elderly.toLocaleString()} ({getPercentPop(elderly)}%) are 65 years of age and older.
      </p>

      <h4 className="f4">Population by Age</h4>

      <Bar height={100} data={
        {
          labels: ageGroups,
          datasets: [dataset]
        }
      } options={
        {
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              { 
                stacked: false,
                ticks: {
                  beginAtZero: true,
                  callback: function(value, index, values) {
                    return value.split(' years')[0]
                  }
                },
                gridLines: {
                  display: false,
                  color: 'white',
                  lineWidth: 2,
                }
              }
            ],
            yAxes: [
              {
                stacked: false,
                ticks: {
                  beginAtZero: true,
                  callback: function(value, index, values) {
                    return value.toLocaleString()
                  }
                },
                gridLines: {
                  display: true,
                  lineWidth: 1,
                  color: 'white',
                  drawBorder: false
                }
              }
            ]
          },
          tooltips: {
            displayColors: false,
            callbacks: {
              label: function(tooltipItem, data) {
                return parseInt(tooltipItem.value).toLocaleString()
                  + ' people (' + parseInt(tooltipItem.value / totalPopulation * 100) + '%)'
              },
              title: function(tooltipItem) {
                return tooltipItem[0].label + ' years'
              }
            }
          },
          plugins: {
            datalabels: {
              display: false
            }
          }
        }
      } />

      <SourceLink href={source.node.Link} source={source.node.Source} year={source.node.Year} />

    </>
  )
}