import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Bar } from "react-chartjs-2"
import SourceLink from "./source-link"

export default ({region, regions}) => {

  const data = useStaticQuery(graphql`
    {
      spf: allSingleParentFamiliesCsv {
        nodes {
          Geography
          SingleParentFamilies
          Percent
        }
      }
      sources: allSourcesCsv {
        nodes {
          Dataset
          Source
          Year
          Link
        }
      }
    }
  `)

  const sortedData = data.spf.nodes
    .filter(o => (regions.concat(['Connecticut', region]).includes(o.Geography)))
    .map(function(o) { let newO = o; newO.Percent = parseFloat(newO.Percent); return newO })  // Apply parseInt() to Value property
    .sort((a,b) => (a.Percent > b.Percent) ? -1 : ((b.Percent > a.Percent) ? 1 : 0))  // Sort from largest to smallest

  const dataset = sortedData.map(o => o.Percent)
  const datasetLabels = sortedData.map(o => o.Geography.split(' County')[0])
  const datasetColors = sortedData.map(o => o.Geography === 'Connecticut' ? 'rgba(0,0,80,1)'
    : o.Geography === region ? 'orange' : 'teal')

  const source = data.sources.nodes.filter(o => o.Dataset === 'singleParentFamilies')[0]

  return ( 
    <>
      <h4 className="f4">Single-Parent Families by County</h4>

      { region.indexOf(datasetLabels[0]) === 0
        ? <p>{region} has the highest single-parent families rate of all counties in Connecticut.</p>
        : region.indexOf(datasetLabels[ datasetLabels.length - 1 ]) === 0
          ? <p>{region} has the lowest single-parent families rate of all counties in Connecticut.</p>
          : ''
      }      

      <div className="w-100">
        <Bar height={100} data={
          {
            labels: datasetLabels,
            datasets: [{
              data: dataset,
              backgroundColor: datasetColors
            }]
          }
        } options={
          {
            legend: {
              display: false,
            },
            scales: {
              xAxes: [
                { 
                  display: true,
                  gridLines: {
                    display: false,
                    drawBorder: false,
                  },
                  ticks: {
                    beginAtZero: true,
                  }
                }
              ],
              yAxes: [
                {
                  display: false,
                  gridLines: {
                    display: false,
                    drawBorder: false,
                  },
                  ticks: {
                    beginAtZero: true,
                  }
                }
              ]
            },
            tooltips: {
              displayColors: false,
              callbacks: {
                label: function(tooltipItem, data) {
                  return tooltipItem.value + '%'
                },
              }
            },
            plugins: {
              datalabels: {
                display: true,
                align: 'bottom',
                anchor: 'end',
                color: 'white',
                formatter: function(value) {
                  return value + '%'
                }
              }
            }
          }
        } />

        <SourceLink href={source.Link} source={source.Source} year={source.Year} />

      </div>

    </>
  )
}