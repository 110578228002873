import React from "react"

export default () => {
  return (
    <section className="bg-white">
      <div className="mw8 center ph3 pv4">
        <h1 className="f1">About</h1>

        <p>
          Connecticut County Data Stories project was developed by
          <a href="https://www.ctdata.org" className="link blue dim"> CTData Collaborative </a>
          to show how the 8 Connecticut counties compare in economy and demographics.
        </p>

        <div class="cf">

          <a href="http://www.unitedservicesct.org/" className="dib fl dim">
              <img src="/usi-logo.png" className="h4" alt="United Services" />
          </a>

          <a href="http://www.ruralhealthct.org/" className="dib fl pr3 dim">
              <img src="/ct-orh-logo.gif" className="h4" alt="CT State Office of Rural Health" />
            </a>

          <a href="https://ctdata.org" className="dib fl pr3 pt3 dim">
              <img src="/ctdata-logo.png" className="h3" alt="CTData Collaborative" />
          </a>

        </div>

      </div>
    </section>
  )
}