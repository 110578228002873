import React from "react"
import SchoolEnrollment from "./school-enrollment"
import SchoolEnrollmentRace from "./school-enrollment-race"
import SchoolEnrollmentNeeds from "./school-enrollment-needs"
import SchoolBullying from "./school-bullying"

export default ({ region, towns, schoolDistricts }) => {
  return (
    <section className="bg-white">
      <div className="mw8 center ph3 pv5">
        <h1 className="f1">Schools</h1>

        <p>
          When publicly reporting aggregate totals, the Connecticut State Department
          of Education minimizes the risk of identification of individual students
          by not releasing the exact number if the total is fewer than 6.
        </p>

        <p>
          Because so many school districts in Connecticut have small enrollment counts,
          we often lose important information. For example, we do not know whether
          a school district had 5 bullying incidents or 1.
        </p>

        <SchoolEnrollment region={region} schoolDistricts={schoolDistricts} />

        <SchoolEnrollmentRace region={region} schoolDistricts={schoolDistricts} />

        <SchoolEnrollmentNeeds region={region} schoolDistricts={schoolDistricts} />

        <SchoolBullying region={region} schoolDistricts={schoolDistricts} />

      </div>
    </section>
  )
}