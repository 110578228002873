import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { HorizontalBar } from "react-chartjs-2"
import SourceLink from "./source-link"
import 'chartjs-plugin-datalabels'

export default ({region, towns}) => {

  const data = useStaticQuery(graphql`
    {
      populationByRace: allPopulationByRaceCsv {
        nodes {
          Geography
          Variable
          Value
        }
      }
      sources: allSourcesCsv {
        nodes {
          Dataset
          Source
          Year
          Link
        }
      }
    }
  `)

  const races = [
    'White alone',
    'Black or African American alone',
    'American Indian and Alaska Native alone',
    'Asian alone',
    'Native Hawaiian and Other Pacific Islander alone',
    'Some other race alone',
    'Two or more races',
    'Hispanic or Latino'
  ];

  // Get values
  const regionData = data.populationByRace.nodes.filter(o => region === o.Geography);
  const totalPopulation = parseInt(regionData.filter(o => o.Variable === `Total`)[0].Value);

  // Define functions
  const asPercent = (r) => Math.round(getRaceCount(r) / totalPopulation * 100);
  const getRaceCount = r => parseInt(regionData.filter(o => o.Variable === r)[0].Value) || 0;
  
  const datasets = races.map(function(r) {
    return {
      label: r,
      data: [getRaceCount(r)]
    }
  })

  // Source
  const source = data.sources.nodes.filter(o => o.Dataset === 'populationByRace')[0]

  return ( 
    <>
      <h4 className="f4">Population by Race</h4>

      <p>
        In {region}, {asPercent('White alone')}% of the population
        are white non-Hispanic, {asPercent('Black or African American alone')}% are black non-Hispanic,
        and {asPercent('Asian alone')}% are Asian non-Hispanic. {asPercent('Hispanic or Latino')}%
        of the population identify as Hispanic or Latino of any race.
      </p>

      <p className="mb4">
        The chart below shows the racial composition of the region.
        People who identify as Hispanic or Latino are listed under one
        category and not included in the numbers for each race.
      </p>

      <HorizontalBar height={80} data={
        {
          labels: ['Race/Ethnicity'],
          datasets: datasets
        }
      } options={
        {
          legend: {
            display: true,
            position: 'top',
            onClick: null,
          },
          scales: {
            xAxes: [
              { 
                stacked: true,
                ticks: {
                  beginAtZero: true,
                  display: false,
                },
                gridLines: {
                  display: false,
                  drawBorder: false,
                }
              }
            ],
            yAxes: [
              {
                display: false,
                stacked: true,
                ticks: {
                  display: true,
                  color: 'green'
                },
                gridLines: {
                  display: false,
                  drawBorder: false,
                }
              }
            ]
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                return data.datasets[tooltipItem.datasetIndex].label + ': ' + parseInt(tooltipItem.value).toLocaleString() + ' ('
                  + Math.round(tooltipItem.value / totalPopulation * 100) + '%)';
              }
            }
          },
          plugins: {
            datalabels: {
              display: true,
              align: 'center',
              anchor: 'center',
              formatter: function(value, context) {
                return value > 20000 ? Math.round(value / totalPopulation * 100) + '%' : null
              },
              color: 'black'
            },
            colorschemes: {
              scheme: 'brewer.PastelOne8'
            }
          }
        }
      } />

      <SourceLink href={source.Link} source={source.Source} year={source.Year} />

    </>
  )
}