import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { HorizontalBar } from "react-chartjs-2"
import SourceLink from "./source-link"

export default ({region, towns}) => {

  const data = useStaticQuery(graphql`
    {
      medianHouseholdIncome: allMedianHouseholdIncomeCsv {
        nodes {
          Geography
          Value
        }
      }
      sources: allSourcesCsv {
        nodes {
          Dataset
          Source
          Year
          Link
        }
      }
    }
  `)

  // From all towns, select only the relevant ones, and sort by value
  const regionData = data.medianHouseholdIncome.nodes
    .filter(o => (towns.includes(o.Geography) || ['Connecticut', region].includes(o.Geography)))  // Choose only towns in region or Connecticut
    .map(function(o) { let newO = o; newO.Value = parseInt(newO.Value); return newO })  // Apply parseInt() to Value property
    .sort((a,b) => (a.Value > b.Value) ? -1 : ((b.Value > a.Value) ? 1 : 0))  // Sort from largest to smallest

  const medianIncome = regionData.map(o => o.Value);
  const medianIncomeLabels = regionData.map(o => o.Geography);
  const medianIncomeColors = regionData.map(
    o => o.Geography === 'Connecticut' ? 'rgba(0,0,80,1)'
      : o.Geography === region ? 'orange' : 'silver'
  );

  const ctIncome = regionData.filter(o => o.Geography === 'Connecticut')[0].Value
  const countyIncome = regionData.filter(o => o.Geography === region)[0].Value

  const source = data.sources.nodes.filter(o => o.Dataset === 'medianHouseholdIncome')[0]

  return ( 
    <>
      <h4 className="f4">Median Household Income by Town in {region}</h4>

      <p>
        Median household income represents the earnings of the "middle"
        household when all households are ordered from poorest to richest. {region} has
        the median household income of ${countyIncome.toLocaleString()},
        which is {countyIncome > ctIncome ? 'higher' : 'lower'} than
        the state's median of ${ctIncome.toLocaleString()}.
        
        By town, it varies from 
        <span className="bb bw3 b--lightest-blue"> ${medianIncome[0].toLocaleString()} in {medianIncomeLabels[0]} down to
          ${medianIncome[medianIncome.length - 1].toLocaleString()} in {medianIncomeLabels[medianIncome.length - 1]}
        </span>.

      </p>

      <HorizontalBar height={200} data={
        {
          labels: medianIncomeLabels,
          datasets: [{
            data: medianIncome,
            backgroundColor: medianIncomeColors
          }]
        }
      } options={
        {
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              { 
                display: false,
                gridLines: {
                  display: false,
                  drawBorder: false,
                },
                ticks: {
                  beginAtZero: true,
                }
              }
            ],
            yAxes: [
              {
                gridLines: {
                  display: false,
                  drawBorder: false,
                }
              }
            ]
          },
          tooltips: {
            displayColors: false,
            callbacks: {
              label: function(tooltipItem, data) {
                return '$' + parseInt(tooltipItem.value).toLocaleString()
              },
            }
          },
          plugins: {
            datalabels: {
              display: true,
              align: 'left',
              anchor: 'end',
              color: 'white',
              formatter: function(value) {
                return '$' + parseInt(value).toLocaleString()
              }
            }
          }
        }
      } />

      <SourceLink href={source.Link} source={source.Source} year={source.Year} />

    </>
  )
}