import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Pie, HorizontalBar } from "react-chartjs-2"
import SourceLink from "./source-link"

export default ({region, towns}) => {

  const data = useStaticQuery(graphql`
    {
      laborForce: allLaborForceCsv {
        nodes {
          Geography
          Employed
          Unemployed
          LaborForce
          UnemploymentRate
        }
      }
      sources: allSourcesCsv {
        nodes {
          Dataset
          Source
          Year
          Link
        }
      }
    }
  `)

  const dataCt = data.laborForce.nodes.filter(o => o.Geography === 'Connecticut')[0];
  const dataCounty = data.laborForce.nodes.filter(o => o.Geography === region)[0];

  const source = data.sources.nodes.filter(o => o.Dataset === 'laborForce')[0]

  const rateSorted = data.laborForce.nodes
    .filter(o => towns.concat(['Connecticut', region]).includes(o.Geography) )
    .map(function(o) { let newO = o; newO.UnemploymentRate = parseFloat(newO.UnemploymentRate); return newO })  // Apply parseInt() to Value property
    .sort((a,b) => (a.UnemploymentRate > b.UnemploymentRate) ? -1 : ((b.UnemploymentRate > a.UnemploymentRate) ? 1 : 0))  // Sort from largest to smallest

  const datasetValues = rateSorted.map(o => o.UnemploymentRate)
  const datasetLabels = rateSorted.map(o => o.Geography)
  const datasetColors = rateSorted.map(o => o.Geography === 'Connecticut' ? 'rgba(0,0,80,1)'
    : o.Geography === region ? 'orange' : 'teal')

  return ( 
    <>
      <h2 className="f2 mb2 mt5 pt3 bt bw4 b--near-white">Labor Force</h2>

      <div className="cf">

        <div className="w-100 w-50-l fl">
          <p className="mt0">
            Labor force includes all persons aged 16 and over who are not on active duty
            and who are not inmates of institutions such as prisons or nursing homes.
          </p>
          
          <p>
            In {region}, {parseInt(dataCounty.LaborForce).toLocaleString()} people are in the labor force.
            Of these, {parseInt(dataCounty.Employed).toLocaleString()} are employed
            and <span className="bg-light-red">{parseInt(dataCounty.Unemployed).toLocaleString()} (
            {dataCounty.UnemploymentRate}%) are unemployed</span>.
            
            The unemployment rate
            of {region} is {dataCounty.UnemploymentRate > dataCt.UnemploymentRate ? 'higher than'
              : dataCounty.UnemploymentRate === dataCt.UnemploymentRate ? 'the same as' : 'lower than'} that
            in Connecticut ({dataCt.UnemploymentRate}%).
          </p>
        </div>

        <div className="w-100 w-50-l fl tc">
          <Pie height={150} data={
            {
              labels: ['Employed', 'Unemployed'],
              datasets: [
                {
                  label: 'Labor Force',
                  data: [dataCounty.Employed, dataCounty.Unemployed],
                  backgroundColor: ['silver', 'teal']
                }
              ]
          }} options={{
            title: {
              display: true,
              text: ['% Unemployed in', region],
              fontStyle: null,
              fontColor: 'black'
            },
            legend: {
              display: false,
            },
            tooltips: {
              enabled: false,
            },
            elements: {
              arc: {
                borderWidth: 1
              }
            },
            plugins: {
              datalabels: {
                display: true,
                anchor: 'end',
                align: 'bottom',
                formatter: function(value, context) {
                  if (context.dataIndex === 1) {
                    return dataCounty.UnemploymentRate
                  }
                  return null
                },
                color: 'white'
              },
              colorschemes: {
                scheme: 'brewer.SetTwo3',
                reverse: true,
              }
            }
          }} />

          <SourceLink href={source.Link} source={source.Source} year={source.Year} />

        </div>
      
      </div>

      <h4>Unemployment Rate by Town in {region}</h4>
      <p>
        In {region}, the highest unemployment rate
        of {datasetLabels[0] === 'Connecticut' ? datasetValues[1] : datasetValues[0]}% is
        in {datasetLabels[0] === 'Connecticut' ? datasetLabels[1] : datasetLabels[0]},
        while the lowest unemployment rate
        of {datasetLabels[datasetLabels.length - 1] === 'Connecticut' ? datasetValues[datasetValues.length - 2] : datasetValues[datasetValues.length - 1]}% is
        in {datasetLabels[datasetLabels.length - 1] === 'Connecticut' ? datasetLabels[datasetLabels.length - 2] : datasetLabels[datasetLabels.length - 1]}.
      </p>

      <HorizontalBar height={200} data={
        {
          labels: datasetLabels,
          datasets: [{
            data: datasetValues,
            backgroundColor: datasetColors,
          }]
        }
      } options={
        {
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              { 
                //display: false,
                gridLines: {
                  display: true,
                  drawBorder: false,
                },
                ticks: {
                  beginAtZero: true,
                }
              }
            ],
            yAxes: [
              {
                gridLines: {
                  display: false,
                  drawBorder: false,
                },
                ticks: {
                  beginAtZero: true,
                }
              }
            ]
          },
          tooltips: {
            displayColors: false,
            callbacks: {
              label: function(tooltipItem, data) {
                return tooltipItem.value + '%'
              },
            }
          },
          plugins: {
            datalabels: {
              display: true,
              align: 'left',
              anchor: 'end',
              color: 'white',
              formatter: function(value) {
                return value + '%'
              }
            }
          }
        }
      } />

      <SourceLink href={source.Link} source={source.Source} year={source.Year} />

    </>
  )
}